.product-address-overview {
  .contentbox {
    + .contentbox {
      margin-top: 20px;
    }

    .search-devices {
      &::placeholder {
        color: rgba($color-white, .65);
      }
    }

    .dropdown__wrapper {
      button {
        text-align: left;

        @include icon($icon-arrow) {
          position: absolute;
          right: 24px;
          font-size: 10px;
          top: calc(50% - 5px);
          transform: rotate(90deg);
        }

        &:after {
          position: absolute;
          right: 50px;
          content: '';
          top: 1px;
          width: 1px;
          // -2px because of border
          height: calc(100% - 2px);
          background-color: rgba(255, 255, 255, 0.25);
        }
      }

      .dropdownchild__wrapper {
        position: fixed;
        background-color: $color-white;
        z-index: 9999;
      }
    }

    .selectedValues__container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }

      .selectedValue {
        margin-top: 5px;
        background-color: $color-primary;
        color: $color-white;
        border: 1px solid $color-primary;
        padding: 9px 35px 9px 20px;
        border-radius: 25px;
        position: relative;
        font-size: 12px;
        margin-right: 10px;
        cursor: pointer;
        transition-property: color, background-color;
        transition-duration: .25s;
        transition-timing-function: ease;

        @include icon($icon-cancel) {
          position: absolute;
          right: 15px;
          top: 50%;
          font-size: 12px;
          line-height: 1;
          transform: translateY(-50%);
        }

        @include hover-focus-active {
          background-color: $color-white;
          color: $color-primary;
        }
      }
    }
  }

  .company__propertylist {
    .list__col {
      max-width: 100%;
      flex-basis: 100%;

      @include media-breakpoint-up(lg) {
        &--cid,
        &--iid {
          max-width: 50%;
          flex-basis: 50%;
        }
      }

      @include media-breakpoint-up(xl) {
        &--cid,
        &--iid {
          max-width: 180px;
          flex-basis: 180px;
        }

        &--address {
          max-width: 590px;
          flex-basis: 590px;
        }
      }

      @include media-breakpoint-up(xxl) {
        &--address {
          max-width: 1052px;
          flex-basis: 1052px;
        }
      }
    }
  }

  .accordion {
    background-color: $white-bg;

    .accordion__head {
      border-radius: 10px;

      &:hover {
        color: #575757;
        background-color: $color-white;
      }
    }

    &[aria-expanded="true"] {
      color: $color-white;
    }
  }

  .btn-export-devices__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .btn-export-devices {
    margin-bottom: 30px;
  }

  .reset-filter-col {
    display: flex;
    justify-content: flex-end;
  }

  .reset-filter {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    color: $color-white;
    transition: color .2s ease;
    text-align: right;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }

    @include icon($icon-cancel) {
      font-size: 12px;
      line-height: 1;
      padding-right: 10px;
    }

    @include hover-focus-active {
      text-decoration: none;
      color: rgba($color-white, .7);
    }
  }
}