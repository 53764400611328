@import '../Select/SelectVariables';

$dropdown-border-radius: 4px;

.dropdown__wrapper {
  .dropdownchild__wrapper {
    border-top-left-radius: $dropdown-border-radius;
    border-top-right-radius: $dropdown-border-radius;
  }

  &.has-reset {
    button {
      background-color: $filter-active;

      &:before {
        display: none;
      }
    }
  }

  .resetFilter {
    height: 100%;
    width: $select-trigger-width;
  }
}