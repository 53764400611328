.nolist {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul,
ol {
  padding-left: 0;
}

ul.ui,
ol.ui {
  ul,
  ol {
    margin-bottom: 12px;
    margin-top: 12px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 17px;
      margin-top: 17px;
    }
  }

  li {
    position: relative;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 17px;
    }
  }
}

ol.ui {
  counter-reset: item;

  li {
    display: block;
    margin-left: 23px;

    &:before {
      color: $color-primary;

      content: counter(item) '.';
      counter-increment: item;

      margin-left: -23px;
      position: absolute;
      left: 0;
    }

    ol li {
      margin-left: 31px;

      &:before {
        content: counters(item, ".");
        margin-left: -31px;
      }
    }
  }
}

ul.ui {
  list-style: none;

  li {
    margin-left: 28px;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-left: -28px;
      margin-right: 21px;

      // triange styles
      @include ptw-arrow;
    }

    ul li:before {
      border-color: transparent transparent transparent $color-text;
    }
  }
}
