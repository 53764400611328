.mode--no_navigation {
  padding-bottom: 70px;
  @include media-breakpoint-up(md) {
    padding-bottom: 120px;
  }
}

.teamoverview__wrapper {
  .popup__form {
    margin-top: 50px;
  }
}

.form-row--with__label {
  flex-direction: column;
  width: 100%;
}

.person__edit__wrapper {
  .absolute__container {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.user__status__actions {
  .button {
    margin-right: 10px;
  }
}

#teamoverview {
  .team__create__account__btn {
    padding: 15px 22px 15px 40px;
    margin: 0;
  }

  .team__filter__wrapper {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-evenly;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    .form-group {
      width: 100%;
      margin: 0 20px 0 0;

      input {
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  .team__filter__wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    justify-content: space-evenly;
    flex-direction: column;

    select:not(:last-child) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      select:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    .form-group {
      width: 100%;
      margin: 10px 0;

      @include media-breakpoint-up(md) {
        margin: 0 20px 0 0;
      }
    }
  }

  .teamoverview__add {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
