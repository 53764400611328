@use 'sass:math';

@import 'SelectVariables';

.select {
  z-index: $z-index-select;
  width: 100%;
  background-color: $transparent-bg;
  cursor: pointer;
  border-radius: $select-border-radius;

  .select__control,
  .select__text {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .select__dropdown-indicator {
    @include center--flex;
    width: 45px;
    height: 44px;
    font-size: 5px;
    line-height: 1;
    color: $color-white;
    border-left: 1px solid $color-gray-light;

    &:after {
      position: relative;
      transition: transform $select-transition-duration $select-transition-timing-function;
    }
  }

  .select__control {
    border: none;
    box-shadow: none;
    background-color: $transparent-bg;
    transition-property: border-color, box-shadow;
    transition-duration: $select-transition-duration;
    transition-timing-function: $select-transition-timing-function;

    &--menu-is-open {
      .select__dropdown-indicator {
        &:after {
          transform: rotateX(180deg);
        }
      }
    }

    &:hover {
      border-color: $color-primary;
      cursor: pointer;
    }
  }

  .select__value-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 20px 0 10px;
    margin: 0 20px;
  }

  .select__value-count {
    @include center--flex;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-primary;
    font-size: 11px;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-right: 5px;
    padding: 2px;
    min-width: $select-value-count-min-size;
    min-height: $select-value-count-min-size;
  }

  .select__single-value,
  .select__multi-value,
  .select__placeholder {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select__placeholder,
  .select__input input {
    display: block;
    width: 100% !important;
    color: $color-text-dark !important;
    padding: 14px 20px !important;
    margin: 0;
  }

  .select__input {
    position: relative;
    display: block !important;
    width: 100%;

    input {
      font-family: inherit;
      border-radius: $select-border-radius;
      background-color: rgba($color-black, .05) !important;
      box-sizing: border-box !important;

      + div {
        display: none;
      }
    }
  }

  .select__placeholder {
    position: absolute;
    top: 20px;
    left: 0;
  }

  .select__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $select-z-index-dropdown;
    width: 100%;
    background-color: $color-white;
    border: none;
    border-radius: 0 0 $select-border-radius $select-border-radius;
    overflow: hidden;
    transition-property: border-color, box-shadow;
    transition-duration: $select-transition-duration;
    transition-timing-function: $select-transition-timing-function;
    margin: 0;
    box-shadow: 0 2px 3px -2px $color-black;

    &--top {
      top: auto;
      bottom: 100%;
      border-radius: $select-border-radius $select-border-radius 0 0;

      .select__menu-list {
        padding-bottom: 0;
      }
    }

    &--bottom {
      .select__menu-list {
        padding-top: 0;
      }
    }

    &-list {
      padding: 20px;
      max-height: 300px;
      overflow-y: auto;
      @include scrollbar();

      .select__option {
        padding: 12px 0;
        border-bottom: 1px solid $color-gray-light;

        &:hover {
          cursor: pointer;

          .selection-btn__box {
            border-color: $color-primary;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .select__option {
    cursor: pointer;
    line-height: 1.2;
    transition-property: color, background-color;
    transition-duration: $select-transition-duration;
    transition-timing-function: $select-transition-timing-function;
    color: $color-gray;

    .selection-btn {
      label {
        color: $color-gray;
      }
    }

    &--is-focused,
    &--is-selected {
      &,
      .selection-btn label {
        color: $color-gray;
      }
    }

    &--is-focused {
      color: $color-primary;
      background-color: $color-white;
    }

    &--is-selected {
      color: $color-primary;
      background-color: $color-white;
    }

    .selection-btn {
      pointer-events: none;
    }
  }

  .select__btn-confirm {
    width: 100%;
    min-width: 0;
    margin-top: 10px;

    &:not([disabled]):active {
      margin-top: 12px;
    }
  }

  &.menu-is-open {
    z-index: $z-index-select--open;
  }

  &.select--is-disabled {
    color: red;
  }

  &.select--is-disabled {
    .select__value-count {
      background-color: red;
    }

    .select__control {
      background-color: $color-gray;
    }

    .select__dropdown-indicator {
      background-color: red;
    }
  }

  &.nosearch {
    .select__control {
      display: none;
    }

    .select__menu--bottom {
      border-radius: 4px;

      .select__menu-list {
        .select__option {
          &:first-child {
            padding-top: 32px;
          }
        }
      }
    }
  }
}


$select-sb-font-sizes: (
  lg: (
    default: 17px
  ),
  md: (
    default: 14px
  ),
  sm: (
    default: 12px
  )
);

$select-sb-size: 1.3529em;
$select-sb-line-height: math.div($select-sb-size, 1em);
$select-sb-box-text-gap: .5em;

$select-sb-color: $color-text;
$select-sb-color--disabled: gray;

$select-sb-border-width: 1px;
$select-sb-border-color: gray;
$select-sb-border-color--active: $color-primary;
$select-sb-border-color--checked: blue;
$select-sb-border-color--disabled: $select-sb-color--disabled;

$select-sb-bg-color: $color-white;
$select-sb-bg-color--checked: yellow;
$select-sb-bg-color--disabled: gray;

$select-sb-switch-width: 2.9412em;
$select-sb-switch-dot-border-gap: 3px;


.selection-btn {
  position: relative;
  line-height: $select-sb-line-height;

  &,
  * {
    box-sizing: border-box;
  }

  @each $identifier, $fontSizes in $select-sb-font-sizes {
    &--#{$identifier} {
      @include responsive-property(font-size, $fontSizes);
    }
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    left: math.div($select-sb-size, 2);
    top: math.div($select-sb-size, 2);
    pointer-events: none;
    opacity: 0;

    &:focus,
    &:active {
      ~ label {
        .selection-btn__box {
          border-color: $select-sb-border-color--active;
          box-shadow: 0 1px 2px rgba($color-black, 0.2);
        }
      }
    }

    &:checked {
      ~ label {
        .selection-btn__box {
          background-color: $color-white;
          border-color: $color-primary;

          &:before,
          &:after {
            opacity: 1;
          }
        }
      }
    }

    &[disabled] {
      ~ label {
        cursor: not-allowed;
        color: $select-sb-color--disabled;

        .selection-btn__box {
          border-color: $select-sb-border-color--disabled;
          background-color: $select-sb-bg-color--disabled;
        }
      }
    }
  }

  label {
    position: relative;
    display: inline-flex;
    color: $select-sb-color;
    cursor: pointer;
    margin-bottom: 0;
  }

  &__box {
    display: block;
    position: relative;
    border: $select-sb-border-width solid $select-sb-border-color;
    margin-right: $select-sb-box-text-gap;
    height: $select-sb-size;
    background-color: $select-sb-bg-color;
    transition-property: background-color, border-color, box-shadow;

    &:before,
    &:after {
      transition-property: width, height, color, background-color, opacity, left, transform;
      transition-duration: .2s;
      transition-timing-function: ease;
    }
  }

  &--checkbox {
    .selection-btn__label {
      display: flex;
    }

    .selection-btn__box {
      border-radius: 4px;
      width: $select-sb-size;
      flex-shrink: 0;

      @include icon($icon-check) {
        position: absolute;
        top: 0;
        left: 0;
        @include center--flex();
        @include square(100%);
        color: $color-primary;
        opacity: 0;
        font-size: 1em;
      }
    }

    input {
      &[disabled] {
        ~ label {
          .selection-btn__box {
            &:before {
              color: $select-sb-color--disabled;
            }
          }
        }
      }
    }
  }
}

.isSelect {
  position: relative;

  &.dropdown__wrapper {
    button {
      text-align: left;
      word-break: break-word;
      white-space: normal;
      padding-right: $select-trigger-width + 9px;

      @include icon($icon-arrow) {
        position: absolute;
        right: math.div($select-trigger-width, 2) - 1px;
        font-size: 10px;
        top: calc(50% - 5px);
        transform: rotate(90deg);
      }

      &:after {
        position: absolute;
        right: $select-trigger-width;
        content: '';
        top: 1px;
        width: 1px;
        // -2px because of border
        height: calc(100% - 2px);
        background-color: rgba(255, 255, 255, 0.25);
      }
    }

    .dropdownchild__wrapper {
      position: fixed;
      background-color: $color-white;
      z-index: 9999;
    }
  }
}

.dropdown__wrapper {
  .resetFilter {
    height: 100%;
    width: $select-trigger-width;
  }
}