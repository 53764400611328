body {
  color: $color-text;
  @include typo--normal;
}

.white-bg {
  background-color: $color-white;
  color: $color-gray;
}

.white-bg--highlighted {
  background-color: $white-bg-highlight;
  border: none !important;
}
