$autosuggest-result-padding: 12px;

.autosuggest {
  position: relative;

  &__dropdown {
    position: fixed;
    z-index: $z-index-autosuggest;
    padding: 20px;
    background-color: $color-white;
    color: $color-text-dark;
    border-radius: $border-radius--form-control;
    overflow: hidden;
    box-shadow: 0 0 15px rgba($color-black, .2);
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      @include square(3px);
    }

    &::-webkit-scrollbar-button {
      @include square(0);
    }

    &::-webkit-scrollbar-thumb {
      background: $color-primary;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  &__resultlist {
    margin-top: $autosuggest-result-padding * -1;
    margin-bottom: $autosuggest-result-padding * -1;
  }

  &__result {
    transition: color .2s ease;
    padding: 13px 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-light;
    }

    &:hover,
    &.selected {
      color: $color-primary;
    }
  }
}

.input-group {
  .autosuggest {
    .resetFilter {
      @include media-breakpoint-up(md) {
        right: 11px;
      }
    }
  }
}