.companies__filter {
  margin-bottom: 30px;
}

.companies__list {
  .company__internal-id {
    position: relative;
    margin-bottom: 30px;

    .internal-id__title {
      font-weight: $font-weight-bold;
      margin-bottom: 15px;
    }

    .loading__animation__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      @include square(100%);
      background-color: rgba($color-white, .5);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;

      .internal-id__title {
        margin-bottom: 25px;
        font-size: 16px;
      }
    }
  }
}
