#faqoverview {
  .faq__overview__title {
    text-align: center;
    margin: 50px 0 35px 0;
  }

  .faq__filter__wrapper {
    display: flex;
    margin-bottom: 40px;

    & > * {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }

      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }

    .form-group {
      margin: 0 20px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
