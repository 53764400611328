body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > main {
    flex-grow: 1;
  }

  .content-spacing {
    @include content-spacing;
  }
}

#master {
  background: $color-background-gradient;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;

  &.mode--login {
    background: $color-background-gradient;
    @include media-breakpoint-up(lg) {
      background: $color-login-gradient;
    }
  }
}
