%contentbox {
  background-color: $white-bg;
  border-radius: $border-radius--contentbox--mobile;
  @include media-breakpoint-up(md) {
    border-radius: $border-radius--contentbox;
  }
}

.contentbox {
  @extend %contentbox;
  display: block;
  padding: 10px;

  @include media-breakpoint-up(sm) {
    padding: 20px;
  }

  @include media-breakpoint-up(md) {
    padding: 40px;
  }

  transition: color $animation-time ease, background-color $animation-time ease;

  &.contentbox--has-loader {
    padding-bottom: 0 !important;
  }
}