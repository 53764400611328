.resetFilter {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 40px;
  transform: translateY(-50%);
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  vertical-align: middle;
  font-size: 12px;
  line-height: 1;
  color: $color-white;
  z-index: 20;
  @include icon($icon-cancel);
}

.form-control {
  &.has-reset {
    padding-right: 40px;
    background-color: $filter-active;

    &.form-control--white-bordered {
      background-color: rgba($filter-active, .1);

      ~ .resetFilter {
        &:before {
          color: $color-text-dark;
        }
      }
    }
  }
}