.download__information {
  display: block;
  color: $color-gray;
  background-color: $color-info--darken;
  padding: 20px 28px;
}

.download {
  display: block;
  color: $color-gray;
  text-align: center;
  background-color: $color-white;

  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  .row {
    display: flex;
    padding: 10px;
    border: 1px solid gray;
    margin: 10px auto 0;

    @include media-breakpoint-up(md) {
      padding: 25px;
    }

    .download__entry__format {
      .format--pdf {
        width: 45px;
        height: 45px;
        position: relative;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
          margin: 0;
        }

        @include icon($icon-pdf) {
          font-size: 45px;
          line-height: 45px;
        }
      }
    }

    .download__content__wrapper {
      align-self: center;
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }

      .download__title {
        text-transform: uppercase;
        @include typo--normal;
        word-break: break-all;

        @include media-breakpoint-up(sm) {
          word-break: normal;
        }

        @include media-breakpoint-up(md) {
          @include typo--large;
        }
      }
    }

    .download__link {
      width: 60px;
      height: 45px;
      border-radius: $border-radius--small;
      border: 1px solid $color-primary;
      position: relative;
      margin: 0 auto;
      display: none;

      transition: background-color $animation-time ease;

      @include media-breakpoint-up(sm) {
        margin: 0;
        display: block;
      }

      @include icon($icon-download) {
        font-size: 24px;
        line-height: 24px;
        color: $color-primary;

        transition: color $animation-time ease;

        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -12px;
        margin-top: -12px;
      }
    }
  }

  @include hover-focus {
    .download__link {
      background-color: $color-primary;

      &:before {
        color: $color-white;
      }
    }
  }

  @include hover-focus-active {
    color: $color-gray;
    text-decoration: none;
    outline: none;
  }

  .download__not__found {
    color: $color-primary;
  }
}

.download {
  &.no__link {
    &:hover {
      @include hover-focus {
        .download__link {
          background-color: $color-white;

          &:before {
            color: $color-primary;
          }
        }
      }
    }
  }
}
