@use 'sass:math';

.link {
  cursor: pointer;
  color: $color-primary;
  transition: color $animation-time ease;
  @include typo--large;

  &:before {
    content:"";
    @include ptw-arrow;

    display: inline-block;
    position: relative;
    top: -1px;
    vertical-align: middle;
    margin-right: 8px;
  }

  @include hover-focus {
    color: $color-gray;
    text-decoration: none;
  }

  &.link--small {
    @include typo--small;
  }

  &.link--back {
    font-size: 11px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px 40px 22px;
    color: $color-white;
    display: inline-block;

    @include media-breakpoint-up(sm) {
      position: static;
      font-size: 14px;
      transform: none;
      padding: 14px 40px 27px;
    }

    &:before {
      transform: rotate(180deg);
      border-color: transparent transparent transparent $color-white;
    }

    //TODO: hover

  }
}

.link-download {
  $linkDownloadIconSize: 24px;

  display: block;
  position: relative;
  min-width: $linkDownloadIconSize;
  min-height: $linkDownloadIconSize;

  @include icon($icon-download) {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: math.div(-$linkDownloadIconSize, 2);
    margin-top: math.div(-$linkDownloadIconSize, 2);
    color: $color-primary;
    font-size: $linkDownloadIconSize;
    line-height: $linkDownloadIconSize;

    transition: color $animation-time ease;
  }

  @include hover-focus {
    &:before {
      color: $color-gray;
    }
  }

  span {
    display: none;
  }
}
