@use 'sass:math';

$statusDotSize: 10px;
$statusDotSize--desktop: 18px;
$statusDotColors: (
  'orange': $color-warning,
  'red': #F00,
  'green': #16FF00
);
$statusDotColorDefault: 'orange';

.status-dot {
  position: relative;
  padding-left: 20px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: math.div(-$statusDotSize, 2);
    display: block;
    width: $statusDotSize;
    height: $statusDotSize;
    background-color: map-get($statusDotColors, $statusDotColorDefault);
    box-shadow: inset 0px -4px 3px rgba(0, 0, 0, 0.5);
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      margin-top: math.div(-$statusDotSize--desktop, 2);
      width: $statusDotSize--desktop;
      height: $statusDotSize--desktop;
    }
  }

  @each $colorName, $color in $statusDotColors {
    @if $colorName != $statusDotColorDefault {
      &--#{$colorName}:before {
        background-color: $color;
      }
    }
  }
}