.react-datepicker__input-container {
  position: relative;

  @include icon($icon-calendar) {
    font-size: 18px;
    line-height: 18px;
    color: $color-primary;

    position: absolute;
    left: 21px;
    top: 50%;
    margin-top: -9px;
  }

  input {
    border: 1px solid $border-color;
    padding: 11px 15px 11px 50px;
    margin-right: auto;
    border-radius: 6px;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding: 15px 22px 14px 50px;
    }
  }
}

.rdrWeekDays {
  background-color: #EEE;
}

.rdrWeekDay {
  color: #575756;
  border: 0.5px solid #D8D8D8;
  font-weight: 800;
}

.rdrDay {
  border: 0.5px solid #D8D8D8;
}

.rdrDayNumber {
  top: 0;
  bottom: 0;
  border: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrSelected, .rdrInRange, .rdrEndEdge, .rdrStartEdge {
  left: -1px;
}

.rdrStartEdge {
  left: 0;
}

.rdrMonth {
  padding: 10px 0 0 0;
}

.rdrCalendarWrapper {
  padding: 10px 20px 20px;
}

.rdrDayToday .rdrDayNumber span:after,
.rdrNextPrevButton:hover {
  background: $color-primary;
}

.rdrNextPrevButton {
  outline: none !important;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  background: $color-primary;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 7px;
}

.rdrNextPrevButton i {
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
  @include icon($icon-arrow) {
    color: $color-white;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
;
}

.rdrNextButton i {
  margin: 0;
  transform: rotate(360deg) !important;
}

.daterange__buttons {
  background-color: #FFF;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px 0;
    padding: 10px 22px 10px 40px;
    outline: none;
  }
}

.daterange__picker__wrapper {
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;

  .calendar__wrapper {
    position: absolute;
    z-index: 10;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 7px;
    box-shadow: $box-shadow;
    overflow: hidden;
  }
}