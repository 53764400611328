.login__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column-reverse;
  padding-top: 100px;

  @include media-breakpoint-up(lg) {
    height: 100vh;
    position: absolute;
    flex-direction: row;
    padding-top: 0;
  }

  .login__left {
    display: flex;
    width: 100%;
    position: relative;
    padding: 60px 20px;

    @include media-breakpoint-up(md) {
      padding: 150px 20px;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 1 0;
      padding: 150px 80px 150px 40px;
    }
    &:before {
      content: "";
      background-image: url('../../../img/login-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-position: center;
    }

    .login__intro {
      position: relative;
      align-self: center;

      @include media-breakpoint-up(lg) {
        max-width: 1000px;
        margin:0 auto;

        @media screen and (max-height: 710px) {
          align-self: normal;
          overflow-y: auto
        }
      }


      h1 {
        margin-bottom: 30px;
      }

      .intro__text {
        @include typo--large;
      }

      ul {
        padding-top: 1rem;
        list-style: none;

        li {
          display: flex;
          @include typo--large;
          @include icon($icon-arrow){
            color: $color-primary;
            border: none;
            margin-right: 20px;
          }
          .list__entry {
            display: inline-block;
          }
        }
      }
    }
  }

  .login__right {
    position: relative;
    display: flex;
    width: 100%;
    @include media-breakpoint-up(lg) {
      flex: 0 0 45%;
      max-width: 533px;
      padding: 150px 48px;
      background: $color-background-gradient;
    }

    .contentbox {
      padding: 34px 20px 50px;
      max-width: 437px;
      margin: 0 auto;
      h3 {
        text-align: center;
        display: block;
        margin: 10px 0 14px;
        font-size: 22px;
        @include media-breakpoint-up(lg) {
          font-size: 46px;
          margin: 10px 0 28px;
        }
      }

      button {
        display: block;
        margin: 30px auto 0;
        font-size: 14px;
      }

      .login__input__icon {
        @include icon($icon-user){
          color: $color-white;
          position: absolute;
          left: 21px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 11px;
          @include media-breakpoint-up(lg) {
            font-size: 14px;
          }
        }
      }

      input[type="text"] {
        padding: 15px 0 15px 40px;
        &.empty {
          border:1px solid $color-primary;
        }
        @include media-breakpoint-up(sm) {
          padding: 24px 0 24px 50px;
        }
      }
    }

    .login__input__wrapper {
      position:relative;
    }

    .login__forgot__pw {
      display: block;
      text-align: center;
      margin-top: 20px;
      color: $white-bg--hover;
      margin-bottom: 40px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
    }

    .login__info__btn {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      width: 40px;
      height: 40px;
      background-color: $white-bg;
      border-radius: 25px;
      position: absolute;
      right:40px;
      bottom: 40px;
      z-index: 2;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
    }
  }

  .right__inner {
    width: 100%;
    margin: 0 20px 50px 20px;

    @include media-breakpoint-up(lg) {
      margin: 30px 20px;
      align-self: center;
    }
  }

  .login__logo {
    position: absolute;
    top: 20px;
    left: 20px;

    @include media-breakpoint-up(lg) {
      top: 50px;
      left: 50px;
    }

    svg {
      width: 121px;
      height: 44px;

      @include media-breakpoint-up(lg) {
        width: 216px;
        height: 81px;
      }
    }
  }
}

