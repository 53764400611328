$font-size--h1: (
        default: 26px,
        md: 46px
);
$line-height--h1: (
        default: 1.2em,
);
// ---
$font-size--h2: (
        default: 15px,
        md: 26px
);
$line-height--h2: (
        default: 1.2em,
);
// ---
$font-size--h3: (
        default: 17px,
        md: 22px
);
$line-height--h3: (
        default: 1.2em,
);

@mixin typo-headline-base {
  display: block;
  font-weight: $font-weight-normal;
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

// ---
//$font-size--h4: (
//        default: 21px,
//        md: 32px
//);
//$line-height--h4: (
//        default: 24px,
//        md: 35px
//);

// ---
//$font-size--h5: (
//        default: 20px,
//        md: 28px
//);
//$line-height--h5: (
//        default: 23px,
//        md: 31px
//);

// ---
//$font-size--h6: (
//        default: 18px,
//        md: 25px
//);
//$line-height--h6: (
//        default: 21px,
//        md: 28px
//);

@mixin typo-h1() {
  @include responsive-property(font-size, $font-size--h1);
  @include responsive-property(line-height, $line-height--h1);
}

@mixin h1 {
  @include typo-headline-base;
  @include typo-h1;
}

@mixin typo-h2() {
  @include responsive-property(font-size, $font-size--h2);
  @include responsive-property(line-height, $line-height--h2);
}

@mixin h2 {
  @include typo-headline-base;
  @include typo-h2;
}

@mixin typo-h3() {
  @include responsive-property(font-size, $font-size--h3);
  @include responsive-property(line-height, $line-height--h3);
}

@mixin h3 {
  @include typo-headline-base;
  @include typo-h3;
}

//@mixin fontsize-h4() {
//  @include responsive-property(font-size, $font-size--h4);
//  @include responsive-property(line-height, $line-height--h4);
//}
//
//@mixin fontsize-h5() {
//  @include responsive-property(font-size, $font-size--h5);
//  @include responsive-property(line-height, $line-height--h5);
//}
//
//@mixin fontsize-h6() {
//  @include responsive-property(font-size, $font-size--h6);
//  @include responsive-property(line-height, $line-height--h6);
//}