.typo-lg {
  @include typo--large;
}

.text--md {
  @include typo--normal;
}

.text--sm {
  @include typo--small;
}
