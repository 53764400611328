.loading__indicator__wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  button {
    width: auto;
  }
}

.filter__wrapper {
  margin-bottom: 40px;

  select {
    cursor: pointer;
    -webkit-appearance: none;
  }

  /**
  changing the styles that WebKit browsers apply to form fields that have been autocompleted.
   */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-white;
    -webkit-box-shadow: 0 0 0 1000px $filter-active inset;
  }

  .searchFilterWrapper {
    position: relative;

    &.form-group {
      margin-bottom: 15px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  .nextCalibrationFilterWrapper {
    width: 100%;
    position: relative;
  }

  #search,
  #nextCalibration,
  #dateRangeFilter {
    &.active,
    &.highlight {
      background-color: $filter-active !important;

      option {
        color: $color-white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  select:not(:last-child) {
    margin-right: 20px;

  }

  .form-group {
    width: 100%;
    margin: 10px 0;

    @include media-breakpoint-up(md) {
      margin: 0 20px 0 0;
    }
  }
}

.products-overview {
  .productbox {
    @include hover-focus-active() {
      .button {
        border-color: $color-primary;
        background-color: $color-white;
        color: $color-primary;
        text-decoration: none;

        &:before {
          color: $color-primary;
        }
      }
    }
  }

  .btn-export-devices__wrapper {
    text-align: right;
    margin-bottom: 40px;
  }
}
