.alert.alert--info {
  @include icon($icon-info, before, false);
  background-color: $color-warning;
  color: $color-white;

  .alert__message {
    a {
      color: $color-white;
    }
  }

  .alert__close {
    &:before,
    &:after {
      background-color: $color-white;
    }
  }
}
