.alert {
  position: relative;
  background-color: $color-white;
  padding: 35px 40px 35px 70px;
  color: $color-gray;
  box-shadow: $box-shadow;

  &:before {
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -17.5px;
    font-size: 35px;
    line-height: 35px;
  }

  .alert__message {
    max-width: 300px;
  }

  .alert__close {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    outline: none;
    background: transparent;
    width: 20px;
    height: 20px;

    transition: transform $animation-time ease;

    &:before,
    &:after{
      content:"";

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 3px;
      background-color: $color-gray;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    span {
      display: none;
    }

    @include hover-focus {
      transform: scale(1.1);
    }
  }
}
