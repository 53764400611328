.propertylist__header {
  background-color: $color-background-color2;
  padding: 15px 20px;
  font-weight: $font-weight-bold;
  color: $color-white;
  border-radius: $accordion-border-radius;
  margin-bottom: 20px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.company__propertylist {
  font-size: 16px;
  line-height: 1.3;

  .form-group {
    margin-bottom: 0;
  }

  .list__col {
    width: 100%;
    max-width: 100%;
    word-break: break-word;

    &--notice {
      display: flex;
      align-items: center;
      color: $color-yellow;

      @include icon($icon-error) {
        padding-right: .5em;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: -15px;

    .list__col {
      margin-top: 15px;

      &[data-title] {
        display: flex;
        align-items: center;

        &:before {
          content: attr(data-title) ':';
          display: inline-block;
          padding-right: .5em;
          font-weight: $font-weight-bold;
          opacity: .8;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;

    .list__col {
      padding-left: 10px;
      padding-right: 10px;
      flex-shrink: 0;
      flex-grow: 0;

      &--import {
        max-width: 26%;
        flex-basis: 26%;
      }

      &--cid {
        max-width: 42%;
        flex-basis: 42%;
      }

      &--active {
        max-width: 28%;
        flex-basis: 28%;
      }

      &--iid {
        max-width: 100%;
        flex-basis: 100%;
      }

      &--name {
        max-width: 100%;
        flex-basis: 100%;
      }

      &--notice {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;

    .list__col {
      padding-left: 10px;
      padding-right: 10px;
      flex-shrink: 0;
      flex-grow: 0;

      &--import {
        max-width: 75px;
        flex-basis: 75px;
      }

      &--cid {
        max-width: 115px;
        flex-basis: 115px;
      }

      &--active {
        max-width: 73px;
        flex-basis: 73px;
      }

      &--iid {
        max-width: 113px;
        flex-basis: 113px;
      }

      &--name {
        max-width: 392px;
        flex-basis: 392px;
      }

      &--notice {
        max-width: 135px;
        flex-basis: 135px;
        margin-left: auto;
        justify-content: flex-end;
      }
    }

    &.has-error {
      .list__col {
        &--name {
          max-width: 262px;
          flex-basis: 262px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .list__col {
      &--import {
        max-width: 94px;
        flex-basis: 94px;
      }

      &--cid {
        max-width: 115px;
        flex-basis: 115px;
      }

      &--active {
        max-width: 94px;
        flex-basis: 94px;
      }

      &--iid {
        max-width: 116px;
        flex-basis: 116px;
      }

      &--name {
        max-width: 533px;
        flex-basis: 533px;
      }

      &--notice {
        max-width: 155px;
        flex-basis: 155px;
      }
    }

    &.has-error {
      .list__col {
        &--name {
          max-width: 378px;
          flex-basis: 378px;
        }
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .list__col {
      &--cid {
        max-width: 155px;
        flex-basis: 155px;
      }

      &--active {
        max-width: 96px;
        flex-basis: 96px;
      }

      &--iid {
        max-width: 178px;
        flex-basis: 178px;
      }

      &--name {
        max-width: 888px;
        flex-basis: 888px;
      }

      &--notice {
        max-width: 208px;
        flex-basis: 208px;
      }
    }

    &.has-error {
      .list__col {
        &--name {
          max-width: 681px;
          flex-basis: 681px;
        }
      }
    }
  }
}