select {
  option {
    color: $color-gray;
  }
}

select.select + .select2-container {
  .select2-selection {
    border-radius: $border-radius--form-control;
    border-color: $white-bg;
    height: auto;
    outline: none;
    background-color: $gray-bg;

    transition: background-color $animation-time ease;

    .select2-selection__rendered {
      padding: 0 20px;
      color: $color-text;
      line-height: 43px;
      font-size: 14px;
    }

    .select2-selection__arrow {
      border-left: 1px solid $white-bg;
      width: 45px;
      height: 100%;
      border-bottom: 3px solid transparent;

      b {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4.5px 0 4.5px;
        border-color: $color-white transparent transparent transparent;

        transform: rotateX(0deg);
        transition: 0.3s ease;
      }
    }
  }

  @include hover-focus {
    .select2-selection {
      background-color: $white-bg;
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      background-color: $white-bg;
    }

    .select2-selection__arrow {
      b {
        transition: 0.3s ease;
        transform: rotateX(180deg);
      }
    }
  }

  @include is-white-bg {
    border-color: $color-gray;

    .select2-selection.select2-selection--single {
      background-color: transparent;
      border-color: $gray-bg;

      .select2-selection__rendered {
        color: $color-gray;
      }

      .select2-selection__arrow {
        border-left-color: $gray-bg;

        b {
          border-color: $color-primary transparent transparent transparent;
        }
      }
    }

    @include hover-focus {
      .select2-selection.select2-selection--single {
        background-color: transparent;
      }
    }

    &.select2-container--open {
      .select2-selection.select2-selection--single {
        background-color: transparent;
      }
    }
  }
}

.select2-container {
  .select2-dropdown {
    border-color: $gray-bg;
    border-radius: $border-radius--form-control;
    background-color: rgba(#1a1a1a, 0.9);

    .select2-results {
      .select2-results__options {
        padding: 14px 20px;
      }

      .select2-results__option {
        color: $color-white;

        &[aria-selected=true],
        &.select2-results__option--highlighted {
          background-color: transparent;
          color: $color-primary;
        }
      }
    }
  }

  &.select2-container--disabled {
    cursor: not-allowed;

    .select2-selection__rendered{
      cursor: not-allowed;
    }
  }

  &.select2-container--white-bg {
    .select2-dropdown {
      border-color: $gray-bg;
      background-color: $color-white;

      .select2-results {
        .select2-results__option {
          color: $color-gray;

          &[aria-selected=true],
          &.select2-results__option--highlighted {
            background-color: transparent;
            color: $color-primary;
          }
        }
      }
    }
  }
}
