button {
  &:not([disabled]):not(.disabled) {
    cursor: pointer;
  }
}

.button {
  @include typo--button;
  outline: none;
  background-color: $color-primary;
  color: $white;
  display: inline-block;
  padding: 9px 12px 11px 20px;
  border-radius: 5px;
  position: relative;
  border: 1px solid transparent;
  margin-bottom: 5px;
  transition: border-color $animation-time ease, color $animation-time ease, background-color $animation-time ease, opacity $animation-time ease;
  white-space: nowrap;

  &:not([disabled]):not(.disabled) {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    padding: 15px 22px 14px 40px;
  }

  @include icon($icon-arrow) {
    font-size: 5px;
    left: 10px;
    @include center--ver;
    color: $white;

    transition: color $animation-time ease;

    @include media-breakpoint-up(md) {
      font-size: 10px;
      left: 20px;
    }
  }

  &:not([disabled]) {
    @include hover-focus {
      border-color: $color-primary;
      background-color: $color-white;
      color: $color-primary;
      text-decoration: none;

      &:before {
        color: $color-primary;
      }
    }
  }

  &[disabled] {
    opacity: 0.3;
  }

  &.button--moreinv {
    background-color: $white;
    color: $color-primary;
    border-color: $color-primary;

    &:before {
      color: $color-primary;

      transition: color $animation-time ease,
    }

    &:not([disabled]) {
      @include hover-focus {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;

        &:before {
          color: $white;
        }
      }
    }
  }
}

button {
  &.button-reset {
    background: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    width: 100%;
  }
}
