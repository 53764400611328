@import "./../../../src/Scss/config/index";

$select-bg: $color-white;
$select-z-index-control: 2;
$select-z-index-dropdown: 1;
$select-transition-duration: .3s;
$select-transition-timing-function: ease;
$select-value-count-min-size: 19px;
$select-border-radius: 4px;

$select-default-padding: 4px;
$select-default-placeholder-color: $color-primary;

$select-trigger-width: 50px;