.bg__overlay {
  background-color: $dark-overlay;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.popup__container {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 85vh;
  transform: translate(0, -50%);

  .popup__wrapper {
    background-color: #FFF;
    color: $color-gray;
    position: absolute;
    left: 50%;
    height: 100%;
    border-radius: 10px;
    width: 100%;
    z-index: 12;
    transform: translate(-50%, 0);
    padding: 80px 15px 40px 15px;
    top: 0;
    margin-bottom: 100px;

    @include media-breakpoint-up(md) {
      margin-bottom: 400px;
      width: 770px;
      padding: 80px 0 45px 45px;
    }

    @include media-breakpoint-up(lg) {
      top: 0;
    }

    .popup__icon--close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      svg {
        width: 25px;
      }
    }

    .popup__scroll {
      overflow: auto;
      position: relative;
      padding-bottom: 70px;
      height: 100%;
      padding-right: 15px;

      @include media-breakpoint-up(md) {
        padding-right: 45px;
      }
    }
  }

  &.popup--lg {
    .popup__wrapper {
      @include media-breakpoint-up(md) {
        width: 900px;
      }
    }
  }
}