.form-group .input__radio {
  @include radiobox-checkbox-base;

  & + label {
    &:before {
      border-radius: 50%;
    }

    &:after {
      content: "";

      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-radio;

      position: absolute;
      left: 3px;
      top: 3px;
      opacity: 0;

      @include media-breakpoint-up(lg) {
        top: 4px;
      }
    }
  }

  &:checked + label:after {
    background-color: $color-primary;
    opacity: 1;
  }
}