@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: relative;
  display: block;
  min-height: 55px;
  color: $color-white;

  @include hover-focus-active {
    text-decoration: none;
    color: $color-white;
    outline: none;
  }

  @include media-breakpoint-up(md) {
    min-height: 105px;
  }

  .loader__inner {
    font-size: 14px;
    white-space: nowrap;
    padding-left: 39px;
    @include center;

    @include icon($icon-continuous) {
      font-size: 26px;
      line-height: 26px;

      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -13px;

      opacity: 0.2;

      transition: opacity $animation-time ease;
      will-change: opacity;
      // icon is not perfectly centered for rotations
      transform-origin: 48.5%;
    }
  }

  @include hover-focus {
    .loader__inner:before {
      opacity: 1;
    }
  }

  &.loader--loading {
    .loader__inner:before {
      opacity: 1;
      @include keyframe-animation('loader--loading', 1s, 0, infinite, linear) {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.loading__animation__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .loading__animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include square(80px);

    &:after {
      content: " ";
      display: block;
      @include square(40px);
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: #FFF transparent #FFF transparent;
      animation: spin 1s linear infinite;

      @include media-breakpoint-up(md) {
        width: 64px;
        height: 64px;
        border-width: 5px;
      }
    }

    &.xs,
    &.xs_inverted {
      @include square(16px);

      &:after {
        @include square(14px);
        border-width: 1px;
      }
    }

    &.detail {
      margin-top: 20%;

      @include media-breakpoint-up(md) {
        margin-top: 17%;
      }
    }

    &.overview {
      margin-top: 10%;
    }

    &.success {
      margin-top: 50%;
    }

    &.history,
    &.component {
      margin-top: 40px;
    }

    &.inverted,
    &.xs_inverted {
      &:after {
        border-color: $color-background-color2 transparent $color-background-color2 transparent;

        @include media-breakpoint-up(md) {
          border-color: $color-background-color2 transparent $color-background-color2 transparent;
        }
      }
    }
  }

  &.loader--page {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;

    .loading__animation {
      &,
      &:after {
        @include square(100px);
      }

      &:after {
        border-width: 7px;
      }
    }
  }
}
