@import 'forms/label';
@import 'forms/input';
@import 'forms/recaptcha';
@import 'forms/select';
@import 'forms/form-group';
@import 'forms/input-group';
@import 'forms/reset';

@mixin radiobox-checkbox-base {
  visibility: hidden;
  position: relative;
  width: 0;
  height: 0;

  & + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 28px;
    @include typo--small;
    position: absolute;

    &:before {
      content: '';

      // makes them 16x16px big
      width: 14px;
      height: 14px;
      border: 1px solid $color-radio;
      transition: border-color $animation-time ease;

      position: absolute;
      left: 0;
      top: 0;

      @include media-breakpoint-up(lg) {
        top: 1px;
      }
    }

    &:after {
      transition: opacity $animation-time ease, color $animation-time ease, background-color $animation-time ease;
      opacity: 0;
    }
  }

  &[disabled] {
    cursor: default;

    & + label {
      cursor: default;
      border-color: #E4E4E4;

      & + span {
        cursor: default;
        color: #E4E4E4;
      }
    }
  }
}

@import 'forms/checkbox';
@import 'forms/radiobox';

.popup__form {

  .popup__form__title {
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  .popup__form__text {
    @include typo--large;
  }

  .form__row__label {
    @include typo--contentbox-tagline;
    font-weight: $font-weight-bold;
    margin: 28px 0 20px 0;

    @include media-breakpoint-up(md) {
      margin: 58px 0 20px 0;
    }
  }

  .form-row {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;

      &:not([class*='mt--']) {
        margin-top: 5px;
      }

      &:not([class*='mb--']) {
        margin-bottom: 5px;
      }
    }

    .form-group {
      width: 100%;

      &:first-child {
        margin-right: 20px;
      }

      .form-control {
        @include media-breakpoint-up(md) {
          max-width: 300px;
        }
      }
    }

    .btn__cancel {
      justify-self: flex-end;
    }

    &.form__bottom {
      justify-content: center;
      position: relative;
      margin-top: 40px;

      @include media-breakpoint-up(md) {
        margin-top: 100px;
      }

      button {
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
          margin: 0 0 0 20px;
        }
      }

      .cancel__btn {
        order: 2;
        @include media-breakpoint-up(md) {
          order: 1;
        }
      }

      .submit__btn {
        order: 3;
        @include media-breakpoint-up(md) {
          order: 2;
        }
      }

      .required__label {
        order: 1;
        position: static;
        margin: 28px 0 20px 0;

        @include media-breakpoint-up(md) {
          order: 3;
          margin: 0;
          position: absolute;
          align-self: center;
          right: 0;
        }
      }
    }
  }

  .popup__form__confirmation {
    p {
      padding: 20px 0 40px;
    }

    .cancel__btn {
      margin: 0 auto;
    }

  }

  &.customer__request {
    ul {
      margin-top: 1em;
      margin-left: 2em;
    }

    textarea {
      height: 200px;
    }
  }

  .popup--lg & {
    .form-row {
      .form-group {
        .form-control {
          @include media-breakpoint-up(lg) {
            max-width: 362px;
          }
        }
      }
    }
  }
}
