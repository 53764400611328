@use 'sass:math';

%productbox-spacing {
  & > * {
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.productbox {
  border-radius: $border-radius--product;
  overflow: hidden;

  & + .productbox {
    margin-top: 20px;
  }

  .productbox__header {
    padding: 6px 10px;
    display: flex;
    min-height: 28px;
    align-items: center;
    position: relative;
    @include typo--productbox-header;
    white-space: nowrap;
    z-index: 0;
    color: $color-white;
    background-color: $color-background-color2;

    @include media-breakpoint-up(md) {
      padding: 10px 20px;
      z-index: 1;
      min-height: 50px;
    }

    .header__left {
      .header__tagline {
        white-space: normal;

        > span {
          display: inline-block;
          padding: 3px 0;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    .header__right {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      padding-left: 30px;

      @extend %productbox-spacing;
    }
  }

  .productbox__calibration-indicator {
    $indicatorSize: 10px;
    $indicatorSize--desktop: 18px;

    position: relative;
    padding-left: 10px;

    @include media-breakpoint-up(sm) {
      padding-left: 14px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 26px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: math.div(-$indicatorSize, 2);
      display: block;
      width: $indicatorSize;
      height: $indicatorSize;
      background-color: #F00;
      box-shadow: inset 0px -4px 3px rgba(0, 0, 0, 0.5);
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        margin-top: math.div(-$indicatorSize--desktop, 2);
        width: $indicatorSize--desktop;
        height: $indicatorSize--desktop;
      }
    }

    &--green:before {
      background-color: #16FF00;
    }

    &--yellow:before {
      background-color: #D6BD00;
    }

    span {
      display: none;

      @include media-breakpoint-up(sm) {
        display: inline-block;
        line-height: 14px;
      }
    }
  }

  .productbox__files-indicator {
    @include icon($icon-folder) {
      font-size: 12px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .productbox__comment-indicator {
    @include icon($icon-comment) {
      font-size: 12px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .productbox__contact__request {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      margin: 0;
    }
  }

  .productbox__link {
    @include hover-focus() {
      text-decoration: none;
    }
  }

  .productbox__body {
    padding: 15px 10px;

    p {
      @include typo--productbox-description;
      margin: 10px 0;
      @include media-breakpoint-up(sm) {
        margin: 10px 0 0;
      }
      @include media-breakpoint-up(lg) {
        margin: 20px 0 30px;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 15px 10px 15px 40px;
    }

    .productbox__title {
      font-size: 12px;
      display: flex;
      align-items: center;

      @include typo--productbox-title;

      &.productbox__title--gear,
      &.productbox__title--software {
        position: relative;

      }

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }

      &.productbox__title--gear {
        @include icon($icon-settings-2);
      }

      &.productbox__title--software {
        @include icon($icon-save);
      }

      &:before {
        font-size: 21px;
        line-height: 21px;
        position: absolute;
        left: 0;
        top: 7px;
        margin-right: 10px;
        color: $color-primary;
        @include media-breakpoint-up(sm) {
          top: -1px;
        }
        @include media-breakpoint-up(md) {
          font-size: 33px;
          line-height: 33px;
          margin-right: 22px;
          left: 6px;
        }
        @include media-breakpoint-up(lg) {
          margin-right: 25px;
        }
        @include media-breakpoint-up(xxl) {
          margin-right: 30px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 20px;
    }

    @include media-breakpoint-up(md) {
      padding: 25px;
      min-height: 125px;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
    }

    @include media-breakpoint-up(xl) {
      padding: 40px;
    }

    & > .row {
      align-items: center;

      @include media-breakpoint-up(md) {

      }
    }

    .body__right {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > * {
        margin-right: 10px;

        @include media-breakpoint-up(md) {
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .productbox__additionaltext {
    padding-left: 46px;
    @include typo--productbox-description;

    @include media-breakpoint-up(sm) {
      text-align: right;
      padding-left: 0;
    }
    @include media-breakpoint-up(xxl) {
      padding-right: 20px;
    }
  }

  .productbox__actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-evenly;
    @extend %productbox-spacing;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }
    @include media-breakpoint-up(xxl) {
      margin-top: 0;
    }
  }
}
