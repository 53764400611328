@use 'sass:math';

// z-index
$z-index-base: 0;
$z-index-select: 2;
$z-index-autosuggest: $z-index-select + 1;
$z-index-header: $z-index-autosuggest + 1;

// font / typo
$font-path: '/Fonts';

$font-family-base: Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// colors
$color-white: #FFF;
$color-gray: #575757;
$color-gray-light: #E0E0E0;
$color-black: #000;

$color-primary: #E42618;

$color-success: #43CA6C;
$color-success--darken: #3AA059;
$color-warning: #FF8400;
$color-error: #F33E49;
$danger--error: #F33E49;
$color-info: $color-white;
$color-info--darken: #EFEFEF;
$color-yellow: #FD3;

$color-text: $color-white;
$color-text-dark: $color-gray;
$body-color: $color-text;

$gray-bg: rgba($color-black, 0.2);
$gray-bg--light: rgba($color-black, 0.1);
$gray-bg--hover: rgba($color-black, .451);

$dark-overlay: rgba($color-black, 0.8);

$transparent-bg: transparent;
$transparent-bg-dark--hover: rgba(255, 255, 255, 0.04);
$transparent-bg-light--hover: rgba(0, 0, 0, 0.0625);

$white-bg: rgba($color-white, 0.1);
$white-bg--hover: rgba($color-white, 0.25);
$white-bg-highlight: rgba($color-white, 0.1);
$white-bg-highlight--hover: rgba($color-white, 0.2);

$red-bg-error: rgba($color-primary, 0.125);
$filter-active: #6B4D53;

$color-background-color1: #285461;
$color-background-color2: #003249;
$color-background-gradient: linear-gradient(120deg, #285461 0%, #003249 100%);

$color-login-gradient: linear-gradient(120deg, #324955 0%, #114555 100%);

$color-radio: $color-gray;

$box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.52);

$border-radius--small: 5px;
$border-radius--medium: 10px;
$border-radius--mobile: 17px;
$border-radius--large: 25px;
$border-radius--form-control: $border-radius--small;
$border-radius--calendar: $border-radius--small;
$border-radius--flyout: $border-radius--medium;
$border-radius--product: $border-radius--medium;
$border-radius--news: $border-radius--medium;
$border-radius--form-control-login: $border-radius--medium;
$border-radius--contentbox--mobile: $border-radius--mobile;
$border-radius--contentbox: $border-radius--large;
// ---

// sizes
$line-height-base: 1.2;
$font-size-base: 1rem * math.div(14, 16); // 14px

// animations
$animation-time: 0.25s;
// ---

// spacing
$padding--inputs: 11px 20px;

$contentSpacing--header: (
  default: 15px,
  md: 40px,
);
$contentSpacing: (
  default: 5px,
  sm: 15px,
  md: 40px,
);

$spaces: (
  small: (
    default: 30px,
    sm: 35px,
    md: 40px,
  ),
  medium: (
    default: 40px,
    sm: 50px,
    md: 60px,
  ),
  large: (
    default: 60px,
    sm: 70px,
    md: 80px,
  ),
);

$space-classes-property: (
  mt: 'margin-top',
  mb: 'margin-bottom',
  pt: 'padding-top',
  pb: 'padding-bottom',
);
// ---

// bootstrap
$input-placeholder-color: rgba($color-white, 0.2);
$input-disabled-bg: $white-bg;
$input-color: $color-white;
$link-color: $color-primary;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px
);

// layout
$grid-gutter-width-small: 20px;

// accordion
$accordion-border-radius: 10px;
$accordion-padding-mobile: 20px;
$accordion-padding-desktop: 40px;

// checkbox
$checkbox-switch-height: 22px;
$checkbox-switch-width: 40px;
$checkbox-switch-dot-gap: 2px;
$checkbox-switch-dot-size: $checkbox-switch-height - ($checkbox-switch-dot-gap * 2);
$checkbox-switch-animation-duration: .3s;
$checkbox-switch-animation-time-func: ease;
$checkbox-switch-color-unchecked: $color-primary;
$checkbox-switch-color-checked: #32DB32;

// select
$z-index-select: 3;
$z-index-select--open: $z-index-select + 1;
$select-padding-x: 11px;
$folmfield-shadow-focus: 0 1px 2px rgba($color-black, 0.2);