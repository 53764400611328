.form-control {
  @include white-bg(true);
  display: block;
  width: 100%;
  padding: 14px 20px;
  color: $input-color;
  background-clip: padding-box;
  border: 1px solid $white-bg--hover;
  font-size: 14px;
  border-radius: $border-radius--form-control;
  outline: none;
  background-color: $transparent-bg;
  transition-property: border-color, background-color, color, padding;
  transition-duration: $animation-time;
  transition-timing-function: ease;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include hover-focus {
    &:not([disabled]) {
      background-color: $transparent-bg-dark--hover;
    }
  }

  @include is-white-bg {
    @include grag-bg(true);
    color: $color-gray;
    background-color: $transparent-bg;

    @include hover-focus {
      &:not([disabled]) {
        background-color: $transparent-bg-light--hover;
      }
    }

    //TODO: let designer check
    border-color: $gray-bg;
    &.form-control--error {
      background-color: $red-bg-error;
      color: $color-primary;

      @include hover-focus {
        &:not([disabled]) {
          background-color: $red-bg-error;
        }
      }
    }

    &.form-control--highlighted {
      border: 1px solid $white-bg;
      background-color: $gray-bg--light;

      @include hover-focus {
        &:not([disabled]) {
          background-color: $gray-bg;
        }
      }
    }
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: $transparent-bg;
    border: 0;
  }

  &.form-control--error {
    background-color: $red-bg-error;
    color: $color-white;

    @include hover-focus {
      &:not([disabled]) {
        background-color: $red-bg-error;
      }
    }
  }

  &.form-control--login {
    padding: 24px 50px;
    border-radius: 10px;
    border: 1px solid $white-bg;
    outline: none;
    @include typo--large;

    @include icon($icon-user) {
      margin-right: 15px;
    }
  }

  &.form-control--highlighted,
  &.form-control--login {
    border: 1px solid $white-bg;
    background-color: $white-bg;

    @include hover-focus {
      &:not([disabled]) {
        background-color: $white-bg-highlight--hover;
      }
    }
  }

  &.form-control--white-bordered {
    border: 1px solid rgba($color-black, .25);
    background-color: $white-bg;
    color: $color-text-dark;

    &::placeholder {
      color: rgba($color-text-dark, .5);
    }

    @include hover-focus {
      &:not([disabled]) {
        border-color: rgba($color-black, .5);
      }
    }
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    text-shadow: 0 0 0 $input-color;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;

    @include is-white-bg {
      color: $color-gray;
    }
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $gray-bg;
    border: 1px solid $gray-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
    cursor: not-allowed;
  }
}

textarea.form-control {
  height: auto;
}