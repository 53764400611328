.tab-navigation {
  @include typo--productbox-description;

  &:not(.active-mobile) {
    .tab-navigation__list {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .tab-navigation__select {
    @include media-breakpoint-up(md) {
      display: none;
    }

    & + .select2 {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .tab-navigation__wrapper {
    overflow: hidden;
    overflow-x: auto;
    display: flex;
  }

  .tab-navigation__list {
    @include typo--large;
    list-style: none;
    border-bottom: 1px solid #565656;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;

    li {
      display: inline-block;
      position: relative;
      white-space: nowrap;

      &:after {
        content: "";
        display: block;

        width: 11px;
        height: 11px;
        transform: rotate(45deg) translateX(-100%) translateY(0);
        transform-origin: 0 11px;
        transition: transform $animation-time ease,
        border-color $animation-time ease,
        background-color $animation-time ease;

        position: absolute;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        left: 50%;
        bottom: -1px;
        background-color: transparent;
      }

      a {
        padding: 10px 23px;
        color: $color-gray;
        display: block;
        background-color: $color-white;

        @include hover-focus {
          text-decoration: none;
          color: $color-primary;
        }
      }

      &:first-child {
        // recenter the arrow
        &:after {
          margin-left: -12px;
        }

        a {
          padding-left: 0;
        }
      }

      &.active {
        &:after {
          transform: rotate(45deg) translateX(-50%) translateY(50%);
          background-color: $white;
          border-color: #565656;
        }

        a {
          color: $color-primary;
        }
      }
    }
  }
}
