footer {
  overflow: hidden;
  padding-bottom: 8px;
  position: absolute;
  bottom: 0;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  a {
    display: inline-block;
    color: rgba($color-white, 0.25);

    transition: color $animation-time ease;

    @include hover-focus {
      color: rgba($color-white, 1);
      text-decoration: none;
    }
  }
}

.footer__inner {
  @include content-spacing;
  padding-left: 15px;
  padding-right: 15px;

  .footer__toggle {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 33px;
    height: 33px;

    padding: 5px 0;

    .footer--open & {
      position: fixed;
      left: 15px;
      bottom: 15px;
    }

    @include media-breakpoint-up(md) {
      left: -5px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    span {
      transition: opacity $animation-time ease, transform $animation-time ease;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 33px;
      text-align: center;

      &.close {
        font-size: 29px;
        color: $color-primary;
        transform-origin: 50%;
        transform: rotate(45deg);
        opacity: 0;

        @include hover-focus {
          transform: rotate(135deg);
        }

        .footer--open & {
          opacity: 1;
        }
      }

      &.open {
        font-size: 30px;
        line-height: 20px;
        opacity: 1;

        .footer--open & {
          opacity: 0;
        }
      }
    }
  }
}

#navigation-footer {
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    transform: translateY(100%);

    transition: transform $animation-time ease;
    background-color: $color-white;

    padding: 80px 15px;

    @include media-breakpoint-up(md) {
      position: static;
      flex-direction: row;
      transform: none;
      background-color: transparent;
      padding: 0;
    }

    .footer--open & {
      transform: none;
    }

    li {
      @include media-breakpoint-up(sm) {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        @include media-breakpoint-down(sm) {
          display: block;
          padding: 10px 0;
          text-align: center;
          color: $color-gray;
          font-size: 16px;

          @include hover-focus {
            color: $color-primary;
          }
        }
      }
    }
  }
}
