/* config */
@import "config/index";

/* vendor scripts */
@import "~bootstrap-scss/functions";
@import "~bootstrap-scss/variables";
@import "~bootstrap-scss/mixins";
@import "~bootstrap-scss/bootstrap-grid";
@import "~bootstrap-scss/transitions";
@import "~bootstrap-scss/utilities/screenreaders";
@import "vendor/fancybox/jquery.fancybox.min";
@import "vendor/datepicker/bootstrap-datepicker3";

@import "vendor/overrides/cookieconsent";
@import "vendor/overrides/fancybox";

/* partials */
@import "partials/alert";

@import "partials/ui/tabnavigation";
@import "partials/ui/loader";
@import "partials/ui/login";
@import "partials/ui/statusDot";
@import "partials/ui/autosuggest";

// content elements / plugins
@import "partials/ui/divider";
@import "partials/download";
@import "partials/textmedia";

@import "partials/product/index";
@import "partials/news/overview";
@import "partials/faq/overview";
@import "partials/faq/navigation";
@import "partials/team/overview";
@import "partials/company/index";

@import "partials/debugger";

/* datepicker */
@import '../../node_modules/react-date-range/dist/styles.css'; // main style file'; // main style file
@import '../../node_modules/react-date-range/dist/theme/default.css'; // main style file
@import 'partials/ui/datepicker';

