$icomoon-font-family: "myptw";
$icomoon-font-path: "/Fonts";

$icon-cancel: "\e919";
$icon-check: "\e918";
$icon-info: "\e915";
$icon-error: "\e916";
$icon-success: "\e917";
$icon-phone: "\e900";
$icon-email: "\e901";
$icon-save: "\e902";
$icon-settings: "\e903";
$icon-settings-2: "\e904";
$icon-user: "\e905";
$icon-arrow: "\e906";
$icon-calendar: "\e907";
$icon-cd: "\e908";
$icon-comment: "\e909";
$icon-continuous: "\e90a";
$icon-download: "\e90b";
$icon-edit: "\e90c";
$icon-folder: "\e90d";
$icon-folder-2: "\e90e";
$icon-garbage: "\e90f";
$icon-headset: "\e910";
$icon-lock: "\e911";
$icon-pdf: "\e912";
$icon-minus-inverted: "\e913";
$icon-plus-inverted: "\e914";
