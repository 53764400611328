@use 'sass:math';

.accordion {
  @extend %contentbox;
  position: relative;
  display: block;
  color: $color-white;
  cursor: pointer;
  border-radius: $accordion-border-radius !important;
  margin-bottom: 20px;
  transition: background-color $animation-time ease;

  .accordion__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: $accordion-padding-mobile;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
      padding-right: 70px;
    }

    @include media-breakpoint-up(md) {
      padding: $accordion-padding-desktop;
    }

    .accordion__tagline {
      flex-grow: 0;
      @include typo--contentbox-tagline;
      position: relative;
      padding-right: 15px;
      margin-right: 55px;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        padding-right: 40px;
        margin-right: 40px;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        right: 0;
        height: 17px;
        top: 50%;
        margin-top: -9px;
        width: 1px;
        background-color: rgba($color-white, 0.4);
        transition: background-color $animation-time ease;

        @include media-breakpoint-up(sm) {
          height: 45px;
          margin-top: -23px;
        }
      }
    }

    .accordion__title {
      @include typo-h3;
      margin: 20px 0 0;
      flex-grow: 1;
      width: 100%;

      @include media-breakpoint-up(sm) {
        margin: 0;
        width: auto;
      }

      @include media-breakpoint-up(md) {
        padding-right: 60px;
      }
    }

    .accordion__toggle {
      $accordionToggleSize: 27px;

      width: $accordionToggleSize;
      height: $accordionToggleSize;

      position: absolute;
      right: $accordion-padding-mobile;
      top: 26px;
      margin-top: math.div(-$accordionToggleSize, 2);
      transition: opacity $animation-time ease, transform $animation-time ease;

      @include media-breakpoint-up(sm) {
        top: 50%;
      }

      @include media-breakpoint-up(md) {
        right: $accordion-padding-desktop;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 6px;
        top: 6px;
        right: 6px;
        bottom: 6px;
        background-color: $color-white;
      }

      &:after {
        color: $color-primary;
        font-size: $accordionToggleSize;
        line-height: $accordionToggleSize;
        position: absolute;
        left: 0;
        top: 0;
      }

      &--plus {
        opacity: 1;
        @include icon($icon-plus-inverted, after);
      }

      &--minus {
        opacity: 0;
        transform: rotate(-90deg);
        @include icon($icon-minus-inverted, after);
      }
    }
  }

  // reset link hover
  @include hover-focus-active {
    text-decoration: none;
    color: $color-gray;
  }

  &:hover,
  &:focus,
  &[aria-expanded="true"] {
    background-color: $color-white;
    color: $color-gray;

    .accordion__tagline {
      color: $color-primary;

      &:after {
        background-color: rgba($color-black, 0.15);
      }
    }
  }

  &[aria-expanded="true"] {
    .accordion__toggle {
      &.accordion__toggle--plus {
        transform: rotate(90deg);
        opacity: 0;
      }

      &.accordion__toggle--minus {
        opacity: 1;
        transform: rotate(0deg);
      }
    }
  }

  .accordion__body {
    padding: $accordion-padding-mobile;
    cursor: default;

    @include media-breakpoint-up(md) {
      padding: $accordion-padding-mobile $accordion-padding-desktop $accordion-padding-desktop;
    }
  }

  .accordion__loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    background-color: rgba($color-black, .5);
    border-radius: $accordion-border-radius;
    overflow: hidden;
    cursor: wait;

    .loading__animation {
      &:after {
        @include square(40px);
        border-width: 3px;
      }
    }
  }

  &--news {
    .accordion__body {
      @include media-breakpoint-up(md) {
        padding: 40px 90px 20px;
      }

      @include media-breakpoint-up(lg) {
        padding: 40px 90px 20px 145px;
      }
    }
  }

  &--faq {
    .accordion__head {
      padding-right: 70px;

      @include media-breakpoint-up(md) {
        padding-right: 120px;
      }

      .accordion__title {
        margin: 0;
      }
    }

    .accordion__category {
      display: none;
      margin: 0 5px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .accordion__body {
      @include media-breakpoint-up(md) {
        padding: 10px 90px 60px 40px;
      }
    }
  }

  &--team {
    color: $color-white;

    @include hover-focus() {
      background-color: $white-bg;
      color: $color-white;
    }

    .accordion__title {
      padding-right: 0;
      margin: 0;
      display: flex;
    }

    .accordion__head {
      padding-right: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: default;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
      }
    }

    .person__firstcol {
      @include media-breakpoint-up(md) {
        width: 33%;
      }
    }

    .status-dot {
      span {
        display: none;

        @include media-breakpoint-up(sm) {
          display: inline-block;
          line-height: 14px;
        }
      }
    }

    .person__company,
    .person__role {
      display: block;
      padding: 5px 0 0 20px;

      @include media-breakpoint-up(md) {
        padding: 5px 0 0 40px;
      }
    }

    .person__role {
      @include media-breakpoint-up(md) {
        flex: 1 0 auto;
      }
      @include media-breakpoint-up(lg) {
        padding: 5px 0 0;
      }
    }

    .person__company {
      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }

    .person__email {
      display: block;
      @include typo--normal;

      @include media-breakpoint-up(md) {
        padding-left: 20px;
      }
    }

    .person__title {
      display: block;

      @include media-breakpoint-up(md) {
        padding-left: 20px;
      }
    }

    .person__edit__icon:not(.disabled) {
      cursor: pointer;

      @include icon($icon-edit) {
        background-color: $white-bg;
        border-radius: 25px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 8px;
        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        &:before {
          right: 40px;
          top: 50%;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          transform: translateY(-50%);
        }
      }

      @include hover-focus() {
        &:before {
          background-color: $white-bg--hover;
        }
      }
    }
  }

  &--slim {
    .accordion__head {
      padding: $accordion-padding-mobile;
    }
  }
}
