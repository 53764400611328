.alert.alert--error {
  @include icon($icon_error, before, false);
  background-color: $color-error;
  color: $color-white;

  .alert__message {
    a {
      color: $color-white;
    }
  }

  .alert__close {
    &:before,
    &:after {
      background-color: $color-white;
    }
  }
}
