@use 'sass:math';

.pagetitle {
  text-align: center;
  margin: 50px 0 35px 0;

  @include media-breakpoint-up(md) {
    margin: 80px 0 48px 0;
  }
}

.row {
  &.small-gutter {
    margin-left: math.div($grid-gutter-width-small, -2);
    margin-right: math.div($grid-gutter-width-small, -2);

    > .col,
    > [class*='col-'] {
      padding-left: math.div($grid-gutter-width-small, 2);
      padding-right: math.div($grid-gutter-width-small, 2);
    }
  }
}