@use 'sass:math';

.input__checkboxwrapper {
  position: relative;
}

.input__checkbox {
  @include radiobox-checkbox-base;

  & + label {
    @include icon($icon-check, after) {
      font-size: 14px;
      color: $color-radio;
      position: absolute;
      left: 2px;
      top: -1px;
    }
  }

  &:checked + label:after {
    color: $color-primary;
    opacity: 1;
  }

  &.passiv + label:after{
    color: $color-primary;
    opacity: 1;
  }
}

.input__switch {
  @include center--ver;
  @include square(1px);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  + label {
    display: inline-block;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
    min-height: $checkbox-switch-height;
    padding-left: $checkbox-switch-width;
    line-height: 1.2;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      height: $checkbox-switch-height;
      width: $checkbox-switch-width;
      background-color: $checkbox-switch-color-unchecked;
      border-radius: math.div($checkbox-switch-height, 2);
      transition: background-color $checkbox-switch-animation-duration $checkbox-switch-animation-time-func;
    }

    &:after {
      top: $checkbox-switch-dot-gap;
      left: $checkbox-switch-width - $checkbox-switch-dot-size - $checkbox-switch-dot-gap;
      @include square($checkbox-switch-dot-size);
      background-color: $color-white;
      border-radius: 50%;
      transition: left $checkbox-switch-animation-duration $checkbox-switch-animation-time-func;
    }

    .label__text {
      padding-left: 12px;
    }
  }

  &:checked {
    + label {
      &:before {
        background-color: $checkbox-switch-color-checked;
      }

      &:after {
        left: $checkbox-switch-dot-gap;
      }
    }
  }

  &.passiv{
    +label{
      &:before {
        top: 0;
        left: 0;
        background-color: $checkbox-switch-color-unchecked;
      }

      &:after {
        top: $checkbox-switch-dot-gap;
        left: $checkbox-switch-width - $checkbox-switch-dot-size - $checkbox-switch-dot-gap;
        @include square($checkbox-switch-dot-size);
        background-color: $color-white;
      }
    }
  }

  &.active{
    + label {
      &:before {
        background-color: $checkbox-switch-color-checked;
      }

      &:after {
        left: $checkbox-switch-dot-gap;
      }
    }
  }
}
