table {
  width: 100%;
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.table {
  tr {
    td {
      padding: 14px 10px;
    }
  }

  thead {
    tr {
      td {
        font-weight: $font-weight-bold;
        @include typo--contentbox-tagline;
        border-bottom: 1px solid #565656;
        white-space: nowrap;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f6f6f6;
      }
    }
  }
}