#newsoverview {
  .news__teaserimage {
    width: 100%;
    height: auto;
    margin-bottom: 27px;
  }

  .news__teaserimage--description {
    color: #7c7c7c;
  }

  .news__overview__title {
    text-align: center;
    margin: 50px 0 35px 0;
  }

  .news__teaser {
    @include typo--large;
    margin-bottom: 25px;
  }

  .news__text {

  }
}
