.company-administration {
  .company {
    background-color: $color-white;
    color: $color-text-dark;
    border-radius: $accordion-border-radius;
    overflow: hidden;
    padding: $accordion-padding-mobile $accordion-padding-mobile $accordion-padding-desktop;
    margin-top: 20px;

    &__header {
      display: flex;
      font-size: 16px;
      line-height: 1.3;

      > *:not(:last-child) {
        padding-right: 20px;

        @include media-breakpoint-up(md) {
          padding-right: 30px;
        }
      }
    }

    &__body {
      position: relative;
      padding-top: 30px;

      .company__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-white, .5);

        .loading__animation {
          height: 100%;
        }
      }
    }

    &__addresses {
      margin-top: 30px;

      table {
        th,
        td {
          &:first-child {
            width: 20%;
          }

          &:last-child {
            width: 40px;
          }
        }
      }

      .address__delete {
        @include icon($icon-garbage);
        color: $color-primary;
        padding: .1em .2em;
        transition: text-shadow .2s ease;
        cursor: pointer;
        text-align: right;
        width: auto;
        margin-left: auto;

        @include hover-focus-active {
          text-shadow: 0 0 5px rgba($color-black, .3);
        }
      }
    }

    .company__add {
      .add__label {
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin-bottom: 15px;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 25px 22px 60px;
      margin-top: 30px;

      &__header {
        .header-col--id {
          min-width: 127px;
        }
      }

      &__body {
        padding: 40px 20px 0;
      }

      &__addresses {
        margin-top: 45px;
      }

      .company__add {
        .add__label {
          margin-bottom: 20px;
        }
      }
    }
  }
}