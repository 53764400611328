#header {
  height: 100px;
  align-items: center;
  color: $color-white;
  position: relative;
  z-index: $z-index-header;
  display: flex;
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    height: 106px;
    margin-bottom: 35px;
  }

  #logo {
    padding-left: 20px;
    width: 122px;
    height: 45px;

    @include media-breakpoint-up(sm) {
      top: 40px;
      width: 150px;
      height: 50px;
      padding-top: 20px;
      padding-left: 40px;
    }

    svg {
      width: 122px;
      height: 45px;

      @include media-breakpoint-up(sm) {
        width: 150px;
        height: 53px;
      }
    }
  }

  .header__actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    @include responsive-property(padding-right, $contentSpacing--header);

    @mixin hide-contextmenu {
      pointer-events: none;
      opacity: 0;
    }

    @mixin show-contextmenu {
      opacity: 1;
      pointer-events: all;
    }

    .header__action {
      position: relative;

      .user__name {
        display: none;
        min-width: 90px;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

      @include media-breakpoint-up(sm) {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.header__action--user {
        .action__content.action__content {
          right: 0;
          transform: none;
        }
      }
    }

    .action__content--overlay {
      z-index:10;
      padding: 0 !important;
      color: $color-gray;
      box-shadow: $box-shadow;
      min-width: 230px;
      @include media-breakpoint-up(sm) {
        min-width: 265px;
      }

      @include media-breakpoint-up(md) {
        min-width: 355px;
      }

      &:before {
        border-color: transparent transparent $color-primary transparent !important;
      }

      .action__content--overlay__header {
        display: block;
        margin-bottom: 25px;

        @include media-breakpoint-up(lg) {
          white-space: nowrap;
        }
      }

      &.contact {
        @include media-breakpoint-up(lg) {
          right:0;
          transform: translateX(calc(50% + -193px));

          &:before {
            right: 17%;
          }
        }
      }

      .cancel__btn {
        margin-top: 43px;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }

      .action__content__label {
        display: flex !important;

        &:before {
          position: absolute;
          top: 2px;
          margin-right: 10px;
          font-size: 17px;
        }

        &.icon-red {
          &:before {
            color: $color-primary;
          }
        }

        @include hover-focus() {
          text-decoration: none;
        }
        @include h3;
        margin-bottom: 0;
        color: #fff;

        &.inverted{
          color: $color-gray;
        }

        &.icon1,
        &.phone {
          @include typo--large();
          @include icon($icon-phone);
          @include hover-focus() {
            &:before {
              @include phone-animation-ringing;
            }
          }
        }
        &.icon4,
        &.mail {
          @include typo--large();
          @include icon($icon-email);
        }
      }

      .upper__wrapper {
        width: 100%;
        background-color: $color-primary;
        color: $color-white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;

        .action__content__contact--name {
            display: block;
            margin-bottom: 15px;
        }
        @include media-breakpoint-up(sm) {
          padding: 40px;
        }
        @include media-breakpoint-up(lg) {
          padding: 50px 65px;
          justify-content: space-between;
          align-items: center;

          .action__content__contact {
            padding: 0 36px;
          }

          .cancel__btn {
            @include typo--productbox-header();
          }
        }

      }

      .lower__wrapper {
        width: 100%;
        padding: 20px;

        @include media-breakpoint-up(sm) {
          padding: 40px;
        }
        @include media-breakpoint-up(lg) {
          padding: 40px 65px 40px 65px;
          .action__content--overlay__header {
            margin-bottom: 30px;
            white-space: nowrap;
          }
        }
      }

      .lower__wrapper__contacts,
      .upper__wrapper__contacts{
        width: 100%;
        background-color: $color-white;
        color: $color-gray;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        .sales__manager__contact {
          white-space: nowrap;
        }

        .sales__manager__contact:not(:last-child) {
          margin-bottom: 30px;
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: 45px;
          }
        }

        .sales__manager__contact--name {
          @include typo--contentbox-tagline();
        }

        h5 {
          margin-bottom: 21px;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(lg) {
            margin-bottom: 28px;
          }
        }

        .action__content__label {
          @include typo--contentbox-tagline();
        }

        .icon--phone {
          @include icon($icon-phone);
        }
      }
      .upper__wrapper__contacts {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    .action__link {
      position: relative;
      color: $color-white;
      display: flex;
      align-items: center;
      line-height: 32px;

      @include media-breakpoint-up(sm) {
        line-height: 40px;
      }

      &:after {
        width: 32px;
        line-height: 32px;
        vertical-align: middle;
        text-align: center;
        color: $color-white;

        left: 0;
        top: 50%;
        margin-top: -16px;

        @include media-breakpoint-up(sm) {
          width: 40px;
          line-height: 40px;
          margin-top: -20px;
        }
      }

      &.action__link--contact {
        @include icon($icon-headset);
      }

      &.action__link--user {
        @include icon($icon-user);
      }

      &:before {
        background-color: $white-bg-highlight;
        width:40px;
        height: 40px;
        text-align: center;
        line-height: 20px;
        padding: 10px 0;
        border-radius:50%;
        font-size: 80%;
        margin: 0 10px 0 0;
        @include media-breakpoint-up(md) {
          margin: 0 10px 0 32px;
          width: 32px;
          height: 32px;
          line-height: 12px;
          font-size: 70%;
        }
      }

      span {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      @include hover-focus {
        text-decoration: none;

        &:before {
          background-color: $white-bg--hover;
        }

        & + .action__content {
          @include show-contextmenu;
        }
      }
    }

    .action__content {
      position: absolute;
      top: 100%;
      background-color: $color-white;
      box-shadow: $box-shadow;
      padding: 13px 0 18px;
      margin-top: 10px;
      border-radius: $border-radius--medium;
      right: 0;
      @include hide-contextmenu;
      transition: opacity $animation-time ease;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      @include media-breakpoint-up(md) {
        right: 50%;
        transform: translateX(calc(50% + 37px));
      }

      @include hover-focus {
        opacity: 1;
        pointer-events: all;

        @include media-breakpoint-up(md) {
          @include show-contextmenu;
        }
      }

      &:before {
        content:"";

        position: absolute;
        bottom: 100%;
        right: 25px;
        margin-left: -4.5px;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4.5px 6px 4.5px;
        border-color: transparent transparent $color-white transparent;

        @include media-breakpoint-up(md) {
          right: 50%;
        }
      }

      // bridge for hover
      &:after {
        content:"";

        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: 10px;
      }

      &.action__content--contextmenu {
        text-align: center;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            a,
            button {
              white-space: nowrap;
              color: $color-gray;
              padding: 12px 50px;
              display: block;
              width: 100%;

              @include hover-focus {
                color: $color-primary;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
