.products-detail {
  .products-detail__graybox {

  }

  .products-detail__graybox--inner {
    padding: 13px 15px 20px 15px;
    margin: 10px 0;
    @include media-breakpoint-up(sm) {
      margin: 30px 0;
    }
    @include media-breakpoint-up(md) {
      padding: 13px 28px 20px 28px;
    }
    background: #EFEFEF;
    color: #575756;
    @include typo--productbox-description;
    font-weight: $font-weight-bold;

    .device__info {
      display: flex;

      .device__info--label {
        width: 40%;
      }
    }
  }

  .tab-navigation {
    padding-bottom: 40px;
  }

  .tab-navigation__tabs {
    margin-top: 37px;
  }

  .productbox__body {
    padding-bottom: 0;
  }

  .calibration__date__wrapper {
    margin-bottom: 50px;
  }

  .bg--grey {
    background-color: #EFEFEF;
    margin: 0 -20px -20px;
    padding: 20px;
    @include media-breakpoint-up(md) {
      margin: 0 -40px -40px;
      padding: 40px;
    }
  }

  .calibration__date__wrapper {
    .save__btn {
      margin-bottom: 0;
    }

    &--info {
      display: block;
      margin-top: 20px;
    }
  }

  .date__picker__placeholder {
    min-width: 210px;

    @include media-breakpoint-up(sm) {
      min-width: 260px;
    }

    @include media-breakpoint-up(md) {
      min-width: 290px;
    }
  }

  .date__picker__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }

    .date__calendar {
      margin-bottom: 15px;
      flex-grow: 1;
      margin-right: 15px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        flex-grow: 0;
      }
    }

    .react-datepicker {
      border: none;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.52);
    }

    .react-datepicker__header {
      border-bottom: none;

      select {
        padding: 5px;
        border: 1px solid #D8D8D8;
        border-radius: 4px;
        outline: none;
      }
    }

    .date__picker__month--select {
      margin-left: 10px;
    }

    .react-datepicker__month {
      margin-top: 0;
    }

    .react-datepicker__day-names {
      margin-top: 25px;
    }

    .react-datepicker__day-name {
      background-color: #F0F0F0;
      font-weight: $font-weight-bold;
    }

    .react-datepicker__navigation {
      top: 30px;
      outline: none;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      border: 1px solid #D8D8D8;
      margin: 0;
      width: 40px;
    }

    .react-datepicker__header__dropdown {
      margin-bottom: 25px;
    }

    .react-datepicker__header {
      background-color: #FFF;
      padding-top: 0;
    }

    .react-datepicker__month-container {
      padding: 20px;
    }

    .react-datepicker__close-icon {
      position: absolute;
      outline: none;
      right: 7px;

      @include media-breakpoint-up(md) {
        right: 25px;
      }

      &:after {
        background-color: transparent;
        padding: 0;
        color: $color-gray;
        font-size: 25px;
        line-height: normal;
      }
    }

    .react-datepicker__current-month {
      display: none;
    }

    .date__picker__month--next,
    .date__picker__month--previous {
      background-color: $color-primary;
      color: #FFF;
      border-radius: 100%;
      width: 32px;
      height: 30px;
      border: none;
      right: 7px;
      position: absolute;
      top: 0;
      outline: none;

      @include icon($icon-arrow) {
        margin-left: 3px;
        margin-top: 2px;
      }
    ;
    }

    .date__picker__month--previous {
      left: 7px;

      &:before {
        transform: rotate(-180deg);
        margin-left: 0;
        margin-right: 3px;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $color-primary;
    }

    .react-datepicker__navigation--next {
      right: 20px;
      color: $color-primary;
      border-left-color: $color-primary;
    }

    .react-datepicker__navigation--previous {
      left: 20px;
      color: $color-primary;
      border-right-color: $color-primary;
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: $color-primary;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $color-primary;
    }
  }

  .date__static {
    display: inline-flex;
    align-items: center;

    @include icon($icon-calendar) {
      color: $color-primary;
      font-size: 1.286em;
      line-height: 1;
      padding-right: .5em;
      margin-top: -.1em;
    }
  }

  .date__label {
    width: 40%;
    margin-right: 30px;
  }

  .device__certificate__headline {
    margin-bottom: 17px;
  }
}
