@use 'sass:math';

@mixin reset-button-styles {
  background-color: transparent;
  outline: none;
  border: none;

  @include hover-focus-active {
    outline: none;
  }
}

@mixin grag-bg($useHover_:false) {
  background-color: $gray-bg;

  @if($useHover_) {
    transition: background-color $animation-time ease;

    @include hover-focus {
      &:not([disabled]) {
        background-color: $gray-bg--hover;
      }
    }
  }
}
@mixin white-bg($useHover_:false) {
  background-color: $white-bg;

  @if($useHover_) {
    transition: background-color $animation-time ease;

    @include hover-focus {
      &:not([disabled]) {
        background-color: $white-bg--hover;
      }
    }

    &.active {
      &:not([disabled]) {
        background-color: $white-bg--hover;
      }
    }
  }
}

@mixin content-spacing {
  @include responsive-property(padding-left, $contentSpacing);
  @include responsive-property(padding-right, $contentSpacing);
}

@mixin is-white-bg {
  .white-bg & {
    @content;
  }
}

@mixin has-error {
  &.error,
  .error & {
    @content;
  }
}

@mixin ptw-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 6px;
  border-color: transparent transparent transparent $color-primary;
}

@mixin icon($icon, $position: before, $styles: true) {
  &:#{$position} {
    @if $styles {
      position: relative;
      display: inline-block;
    }
    @if $icon {
      content: $icon;
    }
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: $icomoon-font-family;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @content;
  }
}
@mixin phone-animation-ringing() {
  animation: 0.3s shake;

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}

@mixin keyframe-animation($name, $duration:$animation-time, $animation-delay:0s, $iteration-count:infinite, $timing:ease-in-out) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }

  & {
    animation-name: #{$name};
    -webkit-animation-name: #{$name};
    -moz-animation-name: #{$name};
    -o-animation-name: #{$name};

    animation-duration: $duration;
    -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
    -o-animation-duration: $duration;

    animation-delay: $animation-delay;
    -webkit-animation-delay: $animation-delay;
    -moz-animation-delay: $animation-delay;
    -o-animation-delay: $animation-delay;

    animation-iteration-count: $iteration-count;
    -webkit-animation-iteration-count: $iteration-count;
    -moz-animation-iteration-count: $iteration-count;
    -o-animation-iteration-count: $iteration-count;

    animation-timing-function: $timing;
    -webkit-animation-timing-function: $timing;
    -moz-animation-timing-function: $timing;
    -o-animation-timing-function: $timing;
  }
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

  @media print {
    list-style: disc;
    margin-left: 20px;
    li {
      padding-left: 0 !important;

      &:before {
        display: none !important;
      }
    }
  }
}

@mixin responsive-property($property, $map) {
  #{$property}: map-get($map, default);
  $smProperty: map-get($map, sm);
  @if $smProperty {
    @include media-breakpoint-up(sm) {
      #{$property}: $smProperty;
    }
  }
  $mdProperty: map-get($map, md);
  @if $mdProperty {
    @include media-breakpoint-up(md) {
      #{$property}: $mdProperty;
    }
  }
  $lgProperty: map-get($map, lg);
  @if $lgProperty {
    @include media-breakpoint-up(lg) {
      #{$property}: $lgProperty;
    }
  }
  $xlProperty: map-get($map, xl);
  @if $xlProperty {
    @include media-breakpoint-up(xl) {
      #{$property}: $xlProperty;
    }
  }
}

@mixin margin-top($map) {
  @include responsive-property(margin-top, $map);
}

@mixin margin-bottom($map) {
  @include responsive-property(margin-bottom, $map);
}

@mixin custom-scrollbar($backgroundColor: $white) {
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button,
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: $color-primary;
    border: 0 none $color-primary;
    border-radius: 2px;

    &:hover {
      background: $color-primary;
    }

    &:active {
      background: $color-primary;
    }
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: $color-primary;
    border: 1px solid $backgroundColor;
    border-radius: 1px;

    &:hover {
      background: $color-primary;
    }

    &:active {
      background: $color-primary;
    }
  }

  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

/**
  added to fix SCSS "syntax" error
 */
@function max($numbers...) {
  @return m#{a}x(#{$numbers})
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers})
}

//------------------------------------------------------------------------------
// aspect ratio
//------------------------------------------------------------------------------
@mixin aspect-ratio($width, $height, $selector:'> *') {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
  #{$selector} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

/******************************************************************************/
/* center content                                                             */
/******************************************************************************/
@mixin center($useChromeFix_:true) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @if ($useChromeFix_) {
    @include is-chrome {
      transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
    }
  }
}

@mixin center--hor($useChromeFix_: true) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @if ($useChromeFix_) {
    @include is-chrome {
      transform: translateX(calc(-50% + 0.5px));
    }
  }
}

@mixin center--ver($useChromeFix_:true) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include is-chrome {
    transform: translateY(calc(-50% + 0.5px));
  }
}

@mixin center--flex($inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  justify-content: center;
  align-items: center;
}

/******************************************************************************/
/* browser special                                                            */
/******************************************************************************/
@mixin is-chrome {
  .is-chrome & {
    @content;
  }
}

@mixin is-mode($mode_) {
  .mode--#{$mode_} & {
    @content;
  }
}

//------------------------------------------------------------------------------
// Scrollbar
//------------------------------------------------------------------------------
@mixin scrollbar($width: 4px, $thumb-color: $color-primary, $track-color: #495057) {
  &::-webkit-scrollbar {
    @include square($width);
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }
}