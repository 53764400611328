@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xj9piu');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xj9piu#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?xj9piu') format('woff2'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xj9piu') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xj9piu') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xj9piu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}