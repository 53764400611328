.extbase-debugger {
  input[type="checkbox"] {
    display: inline-block;
  }
}

.section {
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 50px;
  padding: 25px !important;
  display: block;

  @media screen and (min-width: 768px) {
    padding: 50px !important;
  }
}

.font-sizes span {
  display: inline-block;
  margin-bottom: 10px;
}

.white-bg {
  padding: 20px;
}
