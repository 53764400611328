#main-navigation {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: -10px;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }

    li {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin-right: 10px;
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        margin-top: 10px;
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        color: $color-white;
        @include white-bg(true);
        border-radius: $border-radius--contentbox--mobile;
        font-size: 15px;
        text-align: center;
        padding: 21px;
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
          padding: 26px 26px 26px;
        }
        @include media-breakpoint-up(md) {
          font-size: 21px;
          padding: 32px 32px 32px;
          border-radius: $border-radius--contentbox;
        }

        @include media-breakpoint-up(lg) {
          font-size: 26px;
          padding: 37px 38px 38px;
          border-radius: $border-radius--contentbox;
        }

        @include hover-focus {
          text-decoration: none;
        }
      }
    }
  }
}
